import React from 'react'
import cx from 'classnames'
import styles from './BaseButton.module.scss'

export interface BaseButtonProps {
  size?: 'medium' | 'large'
  color?: 'green' | 'blue'
  className?: string,
  icon?: React.ReactNode
  children?: React.ReactNode
}

const BaseButton = ({
  component: Component,
  size = 'medium',
  color = 'blue',
  icon,
  children,
  className,
  ...rest
}: {
  component: React.ElementType
  [key: string]: any
} & BaseButtonProps) => (
  <Component
    className={cx(
      styles.root,
      styles[size],
      styles[color],
      { [styles.onlyIcon]: !children && icon },
      className
    )}
    {...rest}
  >
    {icon && (
      <div className={styles.icon}>
        {icon}
      </div>
    )}
    {children && (
      <div className="o-body-2 o-body-2_space o-semi-bold">
        {children}
      </div>
    )}
  </Component>
)

export default BaseButton
