import React from 'react'
import { FontAwesomeIcon } from '@roskvartal-fortawesome/react-fontawesome'
import { faCheck } from '@roskvartal-fortawesome/pro-solid-svg-icons'
import cx from 'classnames'
import styles from './Checkbox.module.scss'

export type InputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'checked' | 'onChange'
>

export interface BaseCheckboxProps {
  checked?: boolean
  disabled?: boolean
  onChange?: (value: boolean) => void
  labelText?: string
  inputProps?: InputProps
  className?: string
}

export type CheckboxProps = BaseCheckboxProps & Omit<
  React.HTMLAttributes<HTMLElement>,
  keyof BaseCheckboxProps
>

const Checkbox = ({
  checked,
  disabled,
  onChange,
  children,
  inputProps,
  className,
  ...rest
}: CheckboxProps) => (
  <label
    className={cx(
      styles.root,
      { [styles.disabled]: disabled },
      className
    )}
    {...rest}
  >
    <div className={styles.controlContainer}>
      <input
        className={styles.input}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={e => onChange?.(e.target.checked)}
        {...inputProps}
      />
      <div className={styles.control}>
        <FontAwesomeIcon
          className={styles.icon}
          icon={faCheck}
        />
      </div>
    </div>
    {children && (
      <div className={cx(styles.content, 'o-body-2')}>
        {children}
      </div>
    )}
  </label>
)

export default Checkbox
