import { useHistory } from 'react-router'

const useGoBack = (fallbackRoute: string) => {
  const history = useHistory()

  return () => {
    if (history.action === 'POP') {
      history.push(fallbackRoute)
    } else {
      history.goBack()
    }
  }
}

export default useGoBack
