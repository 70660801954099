import { Suspense, lazy, useState, Dispatch } from 'react'
import cx from 'classnames'
import { useMap } from '@roskvartal-mapkit/react-mapkit'
import ReactDOM from 'react-dom'
import { useModal } from 'react-modal-hook'
import { Switch, Route } from 'react-router-dom'
import { FontAwesomeIcon } from '@roskvartal-fortawesome/react-fontawesome'
import {
  faBars,
  faPlus,
  faMinus,
  faBorderAll
} from '@roskvartal-fortawesome/pro-regular-svg-icons'
import { Button, ButtonGroup } from '../Button'
import LeadModal from '../LeadModal'
import Sidebar from '../Sidebar'
import FiltersButton from '../FiltersButton'
import styles from './MapOverlay.module.scss'
import controlStyles from './Controls.module.scss'

const CategoriesPanel = lazy(() => import('../../routes/CategoriesPanel'))
const ViewObjectPanel = lazy(() => import('../../routes/ViewObjectPanel'))
const SubCategoryObjectsPanel = lazy(
  () => import('../../routes/SubCategoryObjectsPanel')
)
const ObjectNotFound = lazy(() => import('../../routes/ObjectNotFound'))
const CategoryNotFound = lazy(() => import('../../routes/CategoryNotFound'))
const SubCategoryNotFound = lazy(
  () => import('../../routes/SubCategoryNotFound')
)
const FiltersPanel = lazy(() => import('../../routes/FiltersPanel'))
const NotFound = lazy(() => import('../../routes/NotFound'))

interface MapOverlayProps {
  isRosreestrLayerVisible: boolean;
  toggleRosreestrLayer: Dispatch<boolean>
}

const MapOverlay = ({
  isRosreestrLayerVisible,
  toggleRosreestrLayer
}: MapOverlayProps) => {
  const map = useMap()
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const [showLeadModal, hideLeadModal] = useModal(() => (
    <LeadModal onClose={hideLeadModal} />
  ))

  const overlayRoot = document.querySelector('#map-overlay-portal')

  /** @todo Вывести ошибку на UI */
  if (!overlayRoot) {
    return null
  }

  return ReactDOM.createPortal(
    <div className={styles.root}>
      <div
        id="mainPanelSlot"
        className={cx(styles.panelSlot, styles.panelSlotMain)}
      >
        <Switch>
          <Route path="/object-not-found" exact>
            <Suspense fallback="Loading...">
              <ObjectNotFound />
            </Suspense>
          </Route>
          <Route path="/category-not-found" exact>
            <Suspense fallback="Loading...">
              <CategoryNotFound />
            </Suspense>
          </Route>
          <Route path="/subcategory-not-found" exact>
            <Suspense fallback="Loading...">
              <SubCategoryNotFound />
            </Suspense>
          </Route>
          <Route path={['/', '/:categoryId(\\d+)']} exact>
            <Suspense fallback="Loading...">
              <CategoriesPanel />
            </Suspense>
          </Route>
          <Route path="/viewObject/:objectId(\d+)" exact>
            <Suspense fallback="Loading...">
              <ViewObjectPanel />
            </Suspense>
          </Route>
          <Route path="/:categoryId(\d+)/:subcategoryId(\d+)" exact>
            <Suspense fallback="Loading...">
              <SubCategoryObjectsPanel />
            </Suspense>
          </Route>
          <Route path="/filters" exact>
            <Suspense fallback="Loading...">
              <FiltersPanel />
            </Suspense>
          </Route>
          <Route>
            <Suspense fallback="Loading...">
              <NotFound />
            </Suspense>
          </Route>
        </Switch>
      </div>
      <div className={cx(styles.controls, controlStyles.root)}>
        <div className={cx(
          controlStyles.controlArea,
          controlStyles.topLeft
        )}>
          <FiltersButton />
        </div>
        <div className={cx(
          controlStyles.controlArea,
          controlStyles.controlAreaHorizontal,
          controlStyles.topCenter
        )}>
          <Button color="green" size="large" onClick={showLeadModal}>
            Получить поддержку
          </Button>
        </div>
        <div className={cx(
          controlStyles.controlArea,
          controlStyles.controlAreaVertical,
          controlStyles.topRight
        )}>
          <Button
            icon={<FontAwesomeIcon icon={faBars} size="sm" />}
            size="large"
            color="green"
            onClick={() => setSidebarOpen(true)}
            aria-expanded={sidebarOpen}
            aria-controls="app-sidebar"
          />
          <Button
            className={styles.tooltip}
            data-tooltip="Отображать кадастровое деление"
            icon={<FontAwesomeIcon icon={faBorderAll} size="sm" />}
            size="large"
            color={isRosreestrLayerVisible ? 'green' : 'blue'}
            onClick={() => {
              toggleRosreestrLayer(!isRosreestrLayerVisible)
            }}
          />
        </div>
        <div className={cx(
          controlStyles.controlArea,
          controlStyles.controlAreaVertical,
          controlStyles.centerRight
        )}>
          <ButtonGroup orientation="vertical">
            <Button
              icon={<FontAwesomeIcon icon={faPlus} size="sm" />}
              size="large"
              onClick={() => map.setZoom(map.getZoom() + 1)}
            />
            <Button
              icon={<FontAwesomeIcon icon={faMinus} size="sm" />}
              size="large"
              onClick={() => map.setZoom(map.getZoom() - 1)}
            />
          </ButtonGroup>
        </div>
      </div>
      <Sidebar
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      />
    </div>,
    overlayRoot
  )
}

export default MapOverlay
