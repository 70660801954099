import { useRef } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { useAsync } from 'react-use'
import cx from 'classnames'
import {
  faTimes
} from '@roskvartal-fortawesome/pro-regular-svg-icons'
import SidebarSection from './SidebarSection'
import SidebarLink from './SidebarLink'
import Logo from '../Logo'
import { FontAwesomeIcon } from '@roskvartal-fortawesome/react-fontawesome'
import styles from './Sidebar.module.scss'
import { getSidebarMenuElements } from '../../api/map-api'

export interface SidebarProps {
  open: boolean
  onClose?: () => void
}

const Sidebar = ({ open, onClose }: SidebarProps) => {
  const nodeRef = useRef(null)
  const sidebarRoot = document.querySelector('#sidebar-root')
  const menuElementsAsync = useAsync(async () => {
    return await getSidebarMenuElements()
  })

  if (!sidebarRoot) {
    return null
  }

  if (menuElementsAsync.loading) {
    return <span>Загрузка...</span>
  }

  const group = [...new Set(menuElementsAsync.value?.map((element) => {
    return element.group
  }))]

  return ReactDOM.createPortal(
    <CSSTransition
      in={open}
      classNames={{ ...styles }}
      timeout={200}
      nodeRef={nodeRef}
    >
      <header
        id="app-sidebar"
        className={styles.root}
        ref={nodeRef}
      >
        <div className={styles.content}>
          <div className={cx(styles.panel, styles.header)}>
            <a
              href="https://kr-rk.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Logo />
            </a>
            <button
              className={styles.closeBtn}
              type="button"
              onClick={onClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <nav className={styles.nav}>
            {group.map((group) => {
              return (
                <SidebarSection key={group}>
                  {menuElementsAsync.value?.map((element) => {
                    if (element.group !== group) {
                      return null
                    }

                    return (
                      <SidebarLink
                        url={element.url}
                        key={element.name}
                        icon={element.iconUrl}
                        newTab={element.newTab}
                      >
                        {element.name}
                      </SidebarLink>
                    )
                  })}
                </SidebarSection>
              )
            })}
          </nav>
        </div>
      </header>
    </CSSTransition>,
    sidebarRoot
  )
}

export default Sidebar
