import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '../../components/Button'
import { QueryStateContext } from '../../components/QueryStateProvider'
import useGoBack from '../../hooks/useGoBack'
import { ReactComponent as Sliders } from './sliders.svg'
import styles from './FiltersButton.module.scss'

const FiltersButton = () => {
  const { queryState, setQueryState } = useContext(QueryStateContext)
  const history = useHistory()
  const goBack = useGoBack('/')

  const toggle = () => history.location.pathname === '/filters'
    ? goBack()
    : history.push('/filters')

  return (
    <div className={styles.root}>
      <Button
        icon={<Sliders />}
        size="large"
        onClick={toggle}
      />
      {!!queryState.regions?.length && (
        <button
          type="button"
          className={styles.badge}
          onClick={() => setQueryState?.(prevState => ({
            ...prevState,
            regions: []
          }))}
        />
      )}
    </div>
  )
}

export default FiltersButton
