import qs from 'qs'
import axios from 'axios'
import i18n from '../i18n'

const createHttpClient = (baseURL?: string) => axios.create({
  baseURL,
  headers: {
    'Accept-Language': `${i18n.language}, ru;q=0.9`
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, {
      arrayFormat: 'repeat',
      allowDots: true,
      encode: true
    })
  }
})

export const portalApiClient = createHttpClient(
  process.env.REACT_APP_PORTAL_API_URL
)

export const mapApiClient = createHttpClient(process.env.REACT_APP_BASE_API_URL)
