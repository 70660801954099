import { LatLngBounds } from '@roskvartal-mapkit/mapkit'
import { useEffect, useState, useContext } from 'react'
import { useAsync, useThrottle } from 'react-use'
import { useMap, useMapEvents } from '@roskvartal-mapkit/react-mapkit'
import { getMapObjects } from '../../api/map-api'
import {
  matchPath,
  useLocation,
  withRouter
} from 'react-router'
import { QueryStateContext } from '../../components/QueryStateProvider'
import MapMarker from './MapMarker'
import {
  ViewObjectRouteParams
} from '../../routes/ViewObjectPanel/ViewObjectPanel'
import MapObjectItem from '../../models/MapObjectItemModel'

const MapMarkersLayer = withRouter(() => {
  const map = useMap()

  const location = useLocation()

  const [bounds, updateBounds] = useState<LatLngBounds>(map.getBounds())
  const [activeObjectId, setActiveObjectId] = useState<number|null>(null)

  const throttledBounds = useThrottle<LatLngBounds>(bounds, 1000)

  const [mapObjects, updateMapObjects] = useState<MapObjectItem[]>([])
  const { queryState, setQueryState } = useContext(QueryStateContext)

  const [
    lastThrottledBounds,
    setLastThrottledBounds
  ] = useState<LatLngBounds>(map.getBounds())

  useAsync(async () => {
    if (!throttledBounds) {
      return
    }

    const { lat: latNE, lng: lngNE } = throttledBounds.getNorthEast()
    const { lat: latNW, lng: lngNW } = throttledBounds.getNorthWest()
    const { lat: latSW, lng: lngSW } = throttledBounds.getSouthWest()
    const { lat: latSE, lng: lngSE } = throttledBounds.getSouthEast()

    const mapObjects = await getMapObjects({
      border: {
        type: 'Polygon',
        coordinates: [
          [
            [lngNE, latNE],
            [lngNW, latNW],
            [lngSW, latSW],
            [lngSE, latSE],
            [lngNE, latNE]
          ]
        ]
      },
      subcategoryIds: queryState.subcategories?.map(item => item.id),
      regionsIds: queryState.regions
    })

    updateMapObjects(mapObjects)
  }, [
    throttledBounds,
    queryState.subcategories,
    queryState.regions
  ])

  useEffect(() => {
    const matched = matchPath<ViewObjectRouteParams>(location.pathname, {
      path: '/viewObject/:objectId',
      exact: true
    })
    setActiveObjectId(Number(matched?.params?.objectId))
  }, [location])

  useEffect(() => {
    const center = map.getCenter()
    if (
      queryState.center &&
      queryState.center?.lat !== center.lat &&
      queryState.center?.lng !== center.lng
    ) {
      map.setView(queryState.center)
    }
    if (queryState.zoom && queryState.zoom !== map.getZoom()) {
      map.setZoom(queryState.zoom)
    }
  }, [map, queryState.center, queryState.zoom])

  useEffect(() => {
    if (lastThrottledBounds !== throttledBounds && throttledBounds !== null) {
      setLastThrottledBounds(throttledBounds)
    }
  }, [throttledBounds, lastThrottledBounds])

  const onViewUpdate = () => {
    setQueryState?.(({ ...rest }) => {
      return {
        ...rest,
        zoom: map.getZoom(),
        center: map.getCenter()
      }
    })
  }

  const onBoundsUpdate = () => {
    updateBounds(map.getBounds())
    onViewUpdate()
  }

  useMapEvents({
    moveend: onBoundsUpdate,
    zoomend: onBoundsUpdate
  })

  return (
    <>
      {mapObjects.map((mapObject, index) => {
        return (
          <MapMarker
            key={mapObject.id}
            mapObject={mapObject}
            active={mapObject.id === activeObjectId}
          />
        )
      })}
    </>
  )
})

export default MapMarkersLayer
