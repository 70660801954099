import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import '@roskvartal-mapkit/mapkit/dist/leaflet.css'
import './styles/main.scss'
import Map from './components/Map'
import styles from './App.module.scss'
import { GeneralSettingsContext } from './components/GeneralSettingsProvider'

const App = () => {
  const { faviconUrl } = useContext(GeneralSettingsContext)

  return (
    <>
      <Helmet>
        <link rel="icon" href={faviconUrl} type="image/png" />
      </Helmet>
      <div className={styles.root}>
        <Map />
        <div id="sidebar-root" />
      </div>
    </>
  )
}

export default App
