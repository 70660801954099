import { portalApiClient } from './clients'
import { LeadReq } from './schemas/lead'
import LeadSource from '../enums/LeadSource'
import GeneralSettings from '../models/GeneralSettingsModel'

export const createLead = async (lead: LeadReq) => {
  await portalApiClient.post('/Leads', {
    ...lead,
    source: LeadSource.Map
  })
}

export const getGeneralSettings = async () => {
  const { data } = await portalApiClient
    .get<GeneralSettings>('/GeneralSettings')
  return data
}
