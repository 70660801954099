import { useMemo } from 'react'
import { Marker } from '@roskvartal-mapkit/react-mapkit'
import cx from 'classnames'
import { DivIcon, LatLngExpression } from '@roskvartal-mapkit/mapkit'
import MapObjectItem from '../../models/MapObjectItemModel'
import styles from './MapMarker.module.scss'
import { useHistory } from 'react-router'
import MapTooltip from './MapTooltip'

interface MapMarkerProps {
  mapObject: MapObjectItem
  active: boolean
}

const MapMarker = ({
  mapObject,
  active = false
}: MapMarkerProps) => {
  const history = useHistory()
  const icon = useMemo(() => {
    return new DivIcon({
      html: `<img
              src="${mapObject.iconUrl}"
              class="${styles.image}"
              alt="${mapObject.name}"
            />`,
      className: cx(styles.root, {
        [styles.active]: active
      }),
      iconSize: [36, 36],
      iconAnchor: [18, 18]
    })
  }, [active, mapObject])

  const coordinates = useMemo(() => {
    const newCoordinates = [
      mapObject.marker.coordinates[1],
      mapObject.marker.coordinates[0]
    ]
    return newCoordinates as LatLngExpression
  }, [mapObject])

  const zIndexOffset = useMemo(() => (
    active ? 1000 : 0
  ), [active])

  return (
    <Marker
      position={coordinates}
      icon={icon}
      zIndexOffset={zIndexOffset}
      eventHandlers={{
        click: () => {
          history.push(`/viewObject/${mapObject.id}`)
        }
      }}
    >
      <MapTooltip
        opacity={1}
        offset={[20, 0]}
      >
        <div className={styles.tooltipContent}>
          <div className={cx(
            styles.tooltipName,
            'o-body-2',
            'o-body-2_space',
            'o-semi-bold'
          )}>
            {mapObject.name}
          </div>
          {mapObject.description?.trim() && (
            <div className={cx(styles.tooltipDescription, 'o-body-3')}>
              {mapObject.description}
            </div>
          )}
        </div>
      </MapTooltip>
    </Marker>
  )
}

export default MapMarker
