import { setLocale } from 'yup'

setLocale({
  // use constant translation keys for messages without values
  mixed: {
    default: () => ({ key: 'yup.field_invalid' }),
    required: () => ({ key: 'yup.field_required' })
  },
  string: {
    email: () => ({ key: 'yup.string.email' }),
    min: ({ min }: { min: number }) =>
      ({ key: 'yup.string.min', meta: { min } })
  }
})
