import { useEffect } from 'react'
import { useMap } from '@roskvartal-mapkit/react-mapkit'
import { Map, TileLayer, WMSOptions } from '@roskvartal-mapkit/mapkit'
import { stringify } from 'qs'

const paneName: string = 'cadastral'
const Layer = TileLayer.WMS.extend({
  onAdd (map: Map) {
    map.createPane(paneName)
    TileLayer.WMS.prototype.onAdd.call(this, map)
  },

  onRemove (map: Map) {
    document.querySelector(`.rk-${paneName}-pane`)!.remove()
    TileLayer.WMS.prototype.onRemove.call(this, map)
  }
})

function createLayer (customOptions: WMSOptions = {}) {
  const options: WMSOptions = {
    layers: 'show:27,24,23,22',
    maxZoom: 19,
    tileSize: 1024,
    format: 'PNG32',
    transparent: true,
    pane: paneName,
    ...customOptions
  }
  const pkkParams = {
    bboxSR: 102100,
    imageSR: 102100,
    size: '1024,1024',
    f: 'image'
  }
  const url: string =
    `https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer/export?${stringify(pkkParams)}`

  // @ts-ignore
  return new Layer(url, options)
}

const RosreestrLayer = () => {
  const map = useMap()
  const layer = createLayer()

  useEffect(() => {
    map.addLayer(layer)

    return () => {
      map.removeLayer(layer)
    }
  }, [layer, map])

  return null
}

export default RosreestrLayer
