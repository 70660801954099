import React from 'react'
import cx from 'classnames'
import styles from './SidebarLink.module.scss'

export interface SidebarLinkProps {
  icon?: string
  children: React.ReactNode,
  className?: string,
  url: string,
  newTab: boolean
}

const SidebarLink = ({
  icon,
  children,
  className,
  url,
  newTab,
  ...rest
}: SidebarLinkProps) => (
  <a
    className={cx(styles.root, 'o-body-2', className)}
    target={newTab ? '_blank' : '_self'}
    href={url}
    {...rest}
  >
    {icon && <img className={styles.icon} src={icon} alt="" />}
    <span className={icon ? styles.withIcon : styles.withoutIcon}>
      {children}
    </span>
  </a>
)

export default SidebarLink
