import { useState } from 'react'
import cx from 'classnames'
import Modal from '../Modal'
import LeadForm from '../LeadForm'
import Checkbox from '../Checkbox'
import { Button } from '../Button'
import styles from './LeadModal.module.scss'
import { useAsyncFn } from 'react-use'
import { createLead } from '../../api/portal-api'

interface LeadModalProps {
  onClose: () => void
}

const LeadModal = ({ onClose = () => {} }: LeadModalProps) => {
  const [acceptEula, setAcceptEula] = useState(true)
  const [sendLeadState, sendLead] = useAsyncFn(async (data) => {
    await createLead(data)
    onClose()
  })

  return (
    <Modal
      title={'Заявка на консультирование'}
      onClose={onClose}
    >
      <p className={cx(styles.text, 'o-body-2', 'o-body-2_space')}>
        Оставьте ваши контактные данные.
        Мы свяжемся с вами, подробно расскажем о мерах
        поддержки инвесторов и ответим на ваши вопросы.
      </p>
      <LeadForm
        id="lead-form"
        className={styles.form}
        onSubmit={sendLead}
      />
      <Checkbox
        className={styles.checkbox}
        checked={acceptEula}
        onChange={setAcceptEula}
      >
        Я принимаю <a className="o-link" href="#eula">
          условия передачи информации
        </a>
      </Checkbox>
      <Button
        className={styles.button}
        form="lead-form"
        type="submit"
        color="green"
        size="large"
        disabled={!acceptEula || sendLeadState.loading}
      >
        Получить поддержку
      </Button>
    </Modal>
  )
}

export default LeadModal
