import { MouseEvent } from 'react'
import { faTimes } from '@roskvartal-fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@roskvartal-fortawesome/react-fontawesome'
import cx from 'classnames'
import Overlay from './Overlay'
import styles from './Modal.module.scss'

interface ModalProps {
  title: string,
  onClose?: () => void,
  children?: React.ReactNode
}

const Modal = ({
  children,
  title,
  onClose = () => null
}: ModalProps) => {
  const dialogClickHandler = (evt: MouseEvent) => {
    evt.stopPropagation()
    evt.nativeEvent.stopImmediatePropagation()
  }

  return (
    <Overlay onClick={onClose}>
      <div className={styles.root} onClick={dialogClickHandler}>
        <button className={styles.closeBtn} type="button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <span className={cx(styles.title, 'o-body-1', 'o-bold')}>
          {title}
        </span>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </Overlay>
  )
}

export default Modal
