import IMask from 'imask'
import { useEffect, useRef } from 'react'

const useMaskedInput = (
  inputRef: React.MutableRefObject<HTMLInputElement|HTMLTextAreaElement|null>,
  maskOptions: any
) => {
  const maskRef = useRef<any>(null)

  useEffect(() => {
    maskRef.current?.destroy()
    if (!inputRef.current || !maskOptions) {
      return
    }
    maskRef.current = IMask(inputRef.current, maskOptions)
  }, [inputRef, maskOptions])

  return []
}

export default useMaskedInput
