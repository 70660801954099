import { useState } from 'react'
import { MapContainer } from '@roskvartal-mapkit/react-mapkit'
import MapOverlay from '../MapOverlay'
import MapMarkersLayer from './MapMarkersLayer'
import YandexLayer from './YandexLayer'
import RosreestrLayer from './RosreestrLayer'
import styles from './Map.module.scss'

const Map = () => {
  const [isRosreestrLayerVisible, toggleRosreestrLayer] = useState(false)

  return (
    <>
      <MapContainer
        className={styles.root}
        center={[63.621324, 33.232608]}
        zoomControl={false}
        zoom={6}
      >
        <MapOverlay
          isRosreestrLayerVisible={isRosreestrLayerVisible}
          toggleRosreestrLayer={toggleRosreestrLayer}
        />
        <YandexLayer type="map" />
        <MapMarkersLayer />
        {isRosreestrLayerVisible && <RosreestrLayer />}
      </MapContainer>
      <div id="map-overlay-portal" />
    </>
  )
}

export default Map
