import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import TextField from '../TextField'
import { phoneMask } from '../../utils/masks'
import styles from './LeadForm.module.scss'

interface LeadFormProps {
  className?: string,
  id?: string,
  onSubmit: (data: any) => Promise<void>
}

const LeadForm = ({ id, className, onSubmit }: LeadFormProps) => {
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    fullName: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().min(18).required()
  })

  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema)
  })

  return (
    <form
      id={id}
      className={cx(styles.root, className)}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <TextField
        register={register}
        name="fullName"
        label="Ваше ФИО"
        errorMessage={t(errors.fullName?.message?.key)}
        invalid={errors.fullName?.message}
        required
      />
      <TextField
        register={register}
        name="organization"
        label="Название организации"
      />
      <TextField
        register={register}
        name="email"
        type="email"
        label="Адрес электронной почты"
        errorMessage={t(errors.email?.message?.key)}
        invalid={errors.email?.message}
        required
      />
      <TextField
        register={register}
        name="phone"
        type="tel"
        label="Контактный номер телефона"
        errorMessage={t(
          errors.phone?.message?.key,
          errors.phone?.message?.meta
        )}
        invalid={errors.phone?.message}
        mask={phoneMask}
        required
      />
      <TextField
        register={register}
        type="textarea"
        rows={3}
        name="comment"
        label="Ваш вопрос или комментарий"
      />
    </form>
  )
}

export default LeadForm
