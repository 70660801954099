import cx from 'classnames'
import { ChangeEvent, useRef } from 'react'
import useMaskedInput from '../../hooks/useMaskedInput'
import styles from './TextField.module.scss'

interface TextFieldProps {
  register?: any,
  name?: string,
  label?: string,
  type?: 'textarea' | 'number' | 'text' | 'password' | 'email' | 'tel',
  disabled?: boolean,
  readonly?: boolean,
  required?: boolean,
  placeholder?: string,
  rows?: number,
  autosize?: boolean,
  mask?: any,
  invalid?: boolean,
  decoratorLeading?: React.ReactNode,
  decoratorTrailing?: React.ReactNode,
  errorMessage?: string,
  value?: string|number,
  onChange?: (evt: ChangeEvent) => void
}

function getRootTag (label?: string) {
  return label ? 'label' : 'div'
}

function getLabelBlock (label?: string) {
  return label
    ? <span className={cx(styles.label, 'o-body-2')}>{label}</span>
    : null
}

function getErrorMessageBlock (errorMessage?: string) {
  return errorMessage
    ? (
      <span className={cx(styles.errorMessage, 'o-body-2')}>
        {errorMessage}
      </span>
      )
    : null
}

const TextField = ({
  register,
  name,
  label,
  type = 'text',
  disabled = false,
  readonly = false,
  required = false,
  placeholder,
  rows = 1,
  autosize = false,
  mask = null,
  invalid = false,
  decoratorLeading = null,
  decoratorTrailing = null,
  errorMessage,
  value,
  onChange
}: TextFieldProps) => {
  const Root = getRootTag(label)

  const labelBlock = getLabelBlock(label)

  const errorMessageBlock = getErrorMessageBlock(errorMessage)

  const controlRef = useRef<HTMLInputElement|HTMLTextAreaElement|null>(null)

  const registerControl = (node: any) => {
    controlRef.current = node
    register?.(node)
  }

  useMaskedInput(controlRef, mask)

  const controlBlockBindings = {
    className: cx(styles.control, 'o-body-1'),
    ref: registerControl,
    name,
    value,
    disabled,
    readOnly: readonly,
    required,
    placeholder,
    rows,
    onChange
  }

  const controlBlock = type === 'textarea'
    ? <textarea {...controlBlockBindings} />
    : <input {...controlBlockBindings} />

  return (
    <Root
      className={cx(
        styles.root,
        {
          [styles.disabled]: disabled,
          [styles.required]: required,
          [styles.invalid]: invalid
        }
      )}
    >
      {labelBlock}
      {errorMessageBlock}
      <span
        className={cx(styles.controlWrapper)}
      >
        {decoratorLeading && (<span>
          {decoratorLeading}
        </span>)}
        {controlBlock}
      </span>
    </Root>
  )
}

export default TextField
