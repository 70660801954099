import React, { useState, useEffect } from 'react'
import { getGeneralSettings } from '../../api/portal-api'
import GeneralSettingsModel from '../../models/GeneralSettingsModel'

export type ContextProps = Partial<GeneralSettingsModel>

export interface GeneralSettingsProviderProps {
  children: React.ReactNode
}

export const GeneralSettingsContext =
  React.createContext<ContextProps>({} as ContextProps)

const GeneralSettingsProvider = ({
  children
}: GeneralSettingsProviderProps) => {
  const [state, setState] = useState<ContextProps>({})

  useEffect(() => {
    getGeneralSettings().then((genralSettings) => {
      setState(genralSettings)
    })
  }, [])

  return (
    <GeneralSettingsContext.Provider value={state}>
      {children}
    </GeneralSettingsContext.Provider>
  )
}

export default GeneralSettingsProvider
