import React, { useState, useMemo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import AppStateModel from '../../models/AppStateModel'

export type QueryState = Partial<AppStateModel>

export type ContextProps = {
  queryState: QueryState
  setQueryState?: React.Dispatch<React.SetStateAction<QueryState>>
}

export interface QueryStateProviderProps {
  children: React.ReactNode
}

export const STATE_NAME = '_s'

const defaultState: QueryState = {
  subcategories: [],
  regions: []
}

const readStateFromQuery = (): QueryState | null => {
  const value = new URLSearchParams(window.location.search).get(STATE_NAME)
  if (!value) return null
  return JSON.parse(value)
}

const writeStateToQuery = (state: QueryState) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  urlSearchParams.set(STATE_NAME, JSON.stringify(state))
  window.history.replaceState(null, '', `?${urlSearchParams.toString()}`)
}

const initialState = {
  ...defaultState,
  ...readStateFromQuery()
}

export const QueryStateContext = React.createContext<ContextProps>({
  queryState: initialState
})

const QueryStateProvider = ({ children }: QueryStateProviderProps) => {
  const [state, setState] = useState<QueryState>(initialState)
  const location = useLocation()

  const contextValue = useMemo(() => ({
    queryState: state,
    setQueryState: setState
  }), [state])

  useEffect(() => {
    writeStateToQuery(state)
  }, [state, location])

  return (
    <QueryStateContext.Provider value={contextValue}>
      {children}
    </QueryStateContext.Provider>
  )
}

export default QueryStateProvider
