import i18n, { InitOptions } from 'i18next'
import Fetch from 'i18next-fetch-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const options: InitOptions = {
  fallbackLng: 'ru-RU',
  load: 'currentOnly',
  backend: {
    loadPath: '/locales/{{lng}}.json'
  },
  interpolation: {
    escapeValue: false
  }
}

i18n
  .use(Fetch)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(options)

i18n.on('languageChanged', lng => {
  document.documentElement.setAttribute('lang', lng)
})

export default i18n
