import { useRef } from 'react'
import { Tooltip as LeafletTooltip } from '@roskvartal-mapkit/mapkit'
import {
  Tooltip,
  TooltipProps,
  useMap,
  useMapEvents
} from '@roskvartal-mapkit/react-mapkit'
import styles from './MapTooltip.module.scss'

const MapTooltip = (props: TooltipProps) => {
  const ref = useRef<LeafletTooltip>(null)
  const map = useMap()

  useMapEvents({
    zoomend: () => {
      if (
        ref.current &&
        ref.current.isOpen()
      ) { map.closeTooltip(ref.current) }
    }
  })

  return (
    <Tooltip
      ref={ref}
      className={styles.root}
      {...props}
    >
      {props.children}
    </Tooltip>
  )
}

export default MapTooltip
