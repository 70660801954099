import React from 'react'
import BaseButton, { BaseButtonProps } from './BaseButton'

export type ButtonProps = (
  BaseButtonProps &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseButtonProps>
)

const Button = ({
  type = 'button',
  ...rest
}: ButtonProps) => (
  <BaseButton component="button" type={type} {...rest} />
)

export default Button
