import { mapApiClient } from './clients'
import CategoryModel from '../models/CategoryModel'
import SubCategoryModel from '../models/SubCategoryModel'
import MapObjectItem from '../models/MapObjectItemModel'
import MapObjectModel from '../models/MapObjectModel'
import SideMenuElementModel from '../models/SideMenuElementModel'
import RegionModel from '../models/RegionModel'
import { GetMapObjectsReq } from './schemas/get-map-objects'
import { AxiosResponse } from 'axios'

export const getCategories = async () => {
  const { data } = await mapApiClient.get<CategoryModel[]>('/Menu/categories')
  return data
}

export const getSubCategories = async (categoryIds: number[]) => {
  const { data } = await mapApiClient
    .get<SubCategoryModel[]>('/Menu/subcategories', {
      params: {
        categoryIds
      }
    })
  return data
}

export const getMapObjects = async (payload: GetMapObjectsReq) => {
  const { data } = await mapApiClient
    .post<GetMapObjectsReq, AxiosResponse<MapObjectItem[]>>(
      '/MapObjects',
      payload
    )
  return data
}

export const getMapObject = async (objectId: number) => {
  const { data } = await mapApiClient.get<MapObjectModel>(`/MapObjects/${objectId}`)

  data.marker.coordinates = data.marker.coordinates.reverse()

  return data
}

export const getSubcategory = async (subcategoryId: number) => {
  const { data } = await mapApiClient
    .get<SubCategoryModel>(`/Menu/subcategories/${subcategoryId}`)

  return data
}

export const getSidebarMenuElements = async () => {
  const { data } = await mapApiClient.get<SideMenuElementModel[]>('/Menu/side')
  return data
}

export const getRegions = async (subcategoryIds?: number[]) => {
  const { data } = await mapApiClient
    .get<RegionModel[]>('/Region/regions', {
      params: {
        subcategoryIds
      }
    })

  return data
}
