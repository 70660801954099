import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'
import './yup'
import { BrowserRouter as Router } from 'react-router-dom'
// @ts-ignore
import SimpleReactLightbox from 'simple-lightbox'
import { ModalProvider } from 'react-modal-hook'
import { CookiesProvider } from 'react-cookie'
import QueryStateProvider from './components/QueryStateProvider'
import GeneralSettingsProvider from './components/GeneralSettingsProvider'
import 'focus-visible'

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <Router>
        <SimpleReactLightbox>
          <ModalProvider>
            <CookiesProvider>
              <QueryStateProvider>
                <GeneralSettingsProvider>
                  <App />
                </GeneralSettingsProvider>
              </QueryStateProvider>
            </CookiesProvider>
          </ModalProvider>
        </SimpleReactLightbox>
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
