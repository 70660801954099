import React from 'react'
import styles from './SidebarSection.module.scss'

export interface SidebarSectionProps {
  children?: React.ReactNode
}

const SidebarSection = ({
  children
}: SidebarSectionProps) => (
  <div className={styles.root}>
    <div className={styles.links}>
      {children}
    </div>
  </div>
)

export default SidebarSection
