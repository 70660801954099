import { useContext } from 'react'
import styles from './Logo.module.scss'
import {
  GeneralSettingsContext
} from '../../components/GeneralSettingsProvider'

const Logo = () => {
  const {
    logoUrl,
    logoTextUrl
  } = useContext(GeneralSettingsContext)

  return (
    <div className={styles.root}>
      <img
        className={styles.monogram}
        src={logoUrl}
        alt="logo-monogram"
      />
      <img
        className={styles.text}
        src={logoTextUrl}
        alt="logo-text"
      />
    </div>
  )
}

export default Logo
