import '@roskvartal-mapkit/mapkit-plugins/layer/tile/Yandex'
import { useMemo } from 'react'
import { useMap } from '@roskvartal-mapkit/react-mapkit'

interface YandexLayerProps {
  type: 'map' | 'satellite'
}

const YandexLayer = ({
  type = 'map'
}: YandexLayerProps) => {
  const map = useMap()

  useMemo(() => {
    // @ts-ignore
    window.L.yandex(`yandex#${type}`, { mapOptions: { yandexMapDisablePoiInteractivity: true } }).addTo(map)
  }, [map, type])

  return null
}

export default YandexLayer
