import styles from './Overlay.module.scss'

interface OverlayProps {
  onClick: () => void,
  children?: React.ReactNode
}

const Overlay = ({
  children,
  onClick = () => null
}: OverlayProps) => {
  return <div className={styles.root} onClick={onClick}>
    <div className={styles.content}>
      {children}
    </div>
  </div>
}

export default Overlay
