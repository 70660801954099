import cx from 'classnames'
import styles from './ButtonGroup.module.scss'

export interface ButtonGroupProps {
  children: JSX.Element[]
  orientation?: 'horizontal' | 'vertical'
  className?: string
}

const ButtonGroup = ({
  children,
  orientation = 'horizontal',
  className
}: ButtonGroupProps) => (
  <div className={cx(styles.root, styles[orientation], className)}>
    {children}
  </div>
)

export default ButtonGroup
